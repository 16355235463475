import { Box } from "@mui/material";
import ScaleLoader from "react-spinners/ScaleLoader";

export function Loading() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <ScaleLoader
        height={80}
        loading
        margin={6}
        radius={4}
        speedMultiplier={0.8}
        width={8}
        color="#635dff"
      />
    </Box>
  );
}
