import { useAuth0 } from '@auth0/auth0-react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { Dispatch } from 'react';
import { IAlert } from '../../interfaces';
import { envParams, getOptions } from '../../utils';

const deleteAlert = async (
  controlRiskServerUrl: string,
  token: string,
  alertId: string,
): Promise<boolean> => {
  try {
    const url = `${controlRiskServerUrl}/alert/${alertId}`
    const options = getOptions(token)
    await axios.delete(
      url,
      options 
    )
    return true
  } catch (error) {
    return false
  }
}

export function CrDialogDeleteAlert ({
  alertIdToBeDelete,
  setAlertIdToBeDelete,
  alerts,
  setAlerts,
  openDialogDeleteAlert,
  setOpenDialogDeleteAlert,
}: {
  alertIdToBeDelete: string
  setAlertIdToBeDelete: Dispatch<React.SetStateAction<string>>
  alerts: IAlert[] | undefined
  setAlerts: Dispatch<React.SetStateAction<IAlert[] | undefined>>
  openDialogDeleteAlert: boolean
  setOpenDialogDeleteAlert: Dispatch<React.SetStateAction<boolean>>
}) {
  const [loading, setLoading] = React.useState(false)
  const { getAccessTokenSilently } = useAuth0();

  const handleCloseDialogDeleteAlert = (): void => {
    setOpenDialogDeleteAlert(false)
  };

  const handleDeleteAlert = async () => {
    setLoading(true)
    const controlRiskServerUrl = envParams.HOSTNAME_SERVER
    const token = await getAccessTokenSilently()
    const isDeleted = await deleteAlert(controlRiskServerUrl, token, alertIdToBeDelete)
    if (isDeleted) {
      const newAlerts = alerts?.filter((alert) => alert.id !== alertIdToBeDelete)
      setAlerts(newAlerts)
      setAlertIdToBeDelete('')
    }
    setOpenDialogDeleteAlert(false)
    setLoading(false)
  }

  return (
    <Dialog
      open = { openDialogDeleteAlert }
      onClose = { handleCloseDialogDeleteAlert }
    >
      <DialogTitle>
        Elimina Alerta
      </DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Estas seguro de que quieres eliminar esta Alerta. Esta operación es irreversible.
          </DialogContentText>
        </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialogDeleteAlert} color="inherit">Anular</Button>
        <Button variant="contained" onClick={handleDeleteAlert} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Eliminar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}