import { 
  Container,
  createTheme,
  CssBaseline,
  Grid,
  ThemeProvider
} from '@mui/material';
import { Dashboard } from './components/dashboard/Dashboard';
import { useAuth0 } from '@auth0/auth0-react';
import { Landing } from './components/landing/Landing';
import { CrAppBar } from './components/common/CrAppBar';
import { Loading } from './components/loading/Loading';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Loading />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {
        isAuthenticated && <CrAppBar />
      }
      <Container>
        {
          isAuthenticated ?
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Dashboard />
              </Grid>
            </Grid> 
          </> :
          <Landing />
        }        
      </Container>
    </ThemeProvider>
  );
}