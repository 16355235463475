import { useAuth0 } from "@auth0/auth0-react"
import { AccountCircle, Language, Logout } from "@mui/icons-material"
import {
  AppBar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material"
import { useState } from "react"
import { useTranslation } from 'react-i18next'

export function CrAppBar () {
  const { i18n, t } = useTranslation('appBar')
  const { logout, user } = useAuth0()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [anchorElLang, setAnchorElLang] = useState(null)
  
  const handleMenuUser = (event: any) => {
    setAnchorElUser(event.currentTarget)
  };
  const handleCloseUser = () => {
    setAnchorElUser(null)
  };

  const handleMenuLang = (event: any) => {
    setAnchorElLang(event.currentTarget)
  };
  const handleCloseLang = () => {
    setAnchorElLang(null)
  };

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng)
    handleCloseLang()
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {t('title')}
        </Typography>
        {/* <div>
          <IconButton
            size="large"
            aria-label="choose language"
            aria-controls="menu-appbar-lang"
            aria-haspopup="true"
            onClick={handleMenuLang}
            color="inherit"
          >
            <Language />
          </IconButton>
          <Menu
            id="menu-appbar-lang"
            anchorEl={anchorElLang}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElLang)}
            onClose={handleCloseLang}
          >
            <MenuItem onClick={() => changeLanguage('es')}>{t('es')}</MenuItem>
            <MenuItem onClick={() => changeLanguage('en')}>{t('en')}</MenuItem>
          </Menu>
        </div> */}
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar-user"
            aria-haspopup="true"
            onClick={handleMenuUser}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar-user"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUser}
          >
            <MenuItem disabled>{user?.email}</MenuItem>
              <Divider />
            <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
              <ListItemIcon>
                  <Logout fontSize="small" />
              </ListItemIcon>
              {t('sign-out')}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
