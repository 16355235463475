import axios from 'axios';
import { useEffect, useState } from 'react';
import { IAccount, IAlert, IUser } from '../../interfaces';
import {
  addAccountNameToAlert,
  envParams,
  fetchAccounts,
  fetchAlerts,
  fetchUser,
  getEndOfLastMonth,
  getEndOfLastQuarter,
  getEndOfLastWeek,
  getEndOfYesterday,
  getStartOfCurrentWeek,
  getStartOfCurrentYear,
  getStartOfLastMonth,
  getStartOfLastQuarter,
  getStartOfLastWeek,
  getStartOfToday,
  getStartOfYesterday,
  getUserId
} from '../../utils';
import { CrButtonRefresh } from './CrButtonRefresh';
import { CrDialogNewAlert } from './CrDialogNewAlert';
import { CrTableAlerts } from './CrTableAlerts';
import { CrTableDashboard } from './CrTableDashboard';
import { CrTableDrawdown } from './CrTableDrawdown';
import { CrTableHistories } from './CrTableHistories';
import { useAuth0 } from '@auth0/auth0-react';
import { CrAlertNewAccount } from './CrAlertNewAccount';
import { CrDialogConnectTelegram } from './CrDialogConnectTelegram';
import { CrSnackbar } from '../common/CrSnackbar';
import { AlertColor } from '@mui/material';

export function Dashboard() {
  const { user, getAccessTokenSilently } = useAuth0();

  // STATES
  const [accounts, setAccounts] = useState<IAccount[] | undefined>(undefined)
  const [loadingDashboard, setLoadingDashboard] = useState<boolean>(true)
  const [alerts, setAlerts] = useState<IAlert[] | undefined>(undefined)
  const [loadingNewAlert, setLoadingNewAlert] = useState<boolean>(false)
  const [openDialogNewAlert, setOpenDialogNewAlert] = useState<boolean>(false)
  const [openDialogConnectTelegram, setOpenDialogConnectTelegram] = useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [typeSnackbar, setTypeSnackbar] = useState<AlertColor>('info')
  const [messageSnackbar, setMessageSnackbar] = useState<string>('')
  const controlRiskServerUrl = envParams.HOSTNAME_SERVER
  const userId = getUserId(user)
  
  const getAndPopulateTables = async () => {
    const token = await getAccessTokenSilently();

    // Accounts
    let accounts: IAccount[] | undefined = await fetchAccounts(
      controlRiskServerUrl,
      token,
      userId
    )
    if (accounts) {
      const now = new Date()
      const startOfToday = getStartOfToday().getTime() / 1000
      const endOfToday = now.getTime() / 1000
      const startOfYesterday = getStartOfYesterday().getTime() / 1000
      const endOfYesterday = getEndOfYesterday().getTime() / 1000
      const startOfCurrentWeek = getStartOfCurrentWeek().getTime() / 1000
      const startOfLastWeek = getStartOfLastWeek().getTime() / 1000
      const endOfLastWeek = getEndOfLastWeek().getTime() / 1000
      const startOfLastMonth = getStartOfLastMonth().getTime() / 1000
      const endOfLastMonth = getEndOfLastMonth().getTime() / 1000
      const startOfLastQuarter = getStartOfLastQuarter().getTime() / 1000
      const endOfLastQuarter = getEndOfLastQuarter().getTime() / 1000
      const startOfCurrentYear = getStartOfCurrentYear().getTime() / 1000

      console.log(startOfToday, endOfToday)
      
      accounts.forEach((account) => {
        let absInitialPositionsValueForOpenedOrders
        if (account.orders.opened) {
          absInitialPositionsValueForOpenedOrders = Math.abs(
            account.orders.opened.reduce((acc, cur) => {
              return acc + (cur.type === 'buy' ? cur.initialPositionsValue : -cur.initialPositionsValue)
            }, 0)
          )
        }
        let absInitialPositionsValueForPendingOrders
        if (account.orders.pending) {
          absInitialPositionsValueForPendingOrders = Math.abs(
            account.orders.pending.reduce((acc, cur) => {
              return acc + (cur.type === 'buy' ? cur.initialPositionsValue : -cur.initialPositionsValue)
            }, 0)
          )
        }
        account.openedOrderLeverage = absInitialPositionsValueForOpenedOrders ? absInitialPositionsValueForOpenedOrders / account.equity : undefined
        account.pendingOrderLeverage = absInitialPositionsValueForPendingOrders ? absInitialPositionsValueForPendingOrders / account.equity : undefined
        account.floating = (account.profit / account.balance) * 100
        const updatedAt = new Date(account.updatedAt)
        account.isConnected = ((now.getTime() - updatedAt.getTime()) / (60 * 1000)) < 5

        let balance = 0
        account.history = {
          ...account.history,
          allTime: 0,
          currentYear: 0,
          lastQuarter: 0,
          lastMonth: 0,
          lastWeek: 0,
          currentWeek: 0,
          yesterday: 0,
          today: 0
        }
        account.history?.histories?.forEach((history) => {
          if (history.type === 'OPERATION') {
            account.history.allTime += history.amount / balance
            if (history.executedAt >= startOfCurrentYear) {
              account.history.currentYear += history.amount / balance
            }
            if (history.executedAt >= startOfLastQuarter && history.executedAt <= endOfLastQuarter) {
              account.history.lastQuarter += history.amount / balance
            }
            if (history.executedAt >= startOfLastMonth && history.executedAt <= endOfLastMonth) {
              account.history.lastMonth += history.amount / balance
            }
            if (history.executedAt >= startOfLastWeek && history.executedAt <= endOfLastWeek) {
              account.history.lastWeek += history.amount / balance
            }
            if (history.executedAt >= startOfCurrentWeek && history.executedAt <= endOfToday) {
              account.history.currentWeek += history.amount / balance
            }
            if (history.executedAt >= startOfYesterday && history.executedAt <= endOfYesterday) {
              account.history.yesterday += history.amount / balance
            }
            if (history.executedAt >= startOfToday && history.executedAt <= endOfToday) {
              account.history.today += history.amount / balance
            }
          }
          balance += history.amount
        })
      })
    }
    setAccounts(accounts)

    // Alerts
    const alerts: IAlert[] | undefined = await fetchAlerts(controlRiskServerUrl, token, userId)
    addAccountNameToAlert(alerts, accounts)
    setAlerts(alerts)

    setLoadingDashboard(false)
  }

  // EFFECTS  
  useEffect((): void => {
    getAndPopulateTables()
  }, [])

  // HANDLERS  
  const handlerRefresh = (): void => {
    setLoadingDashboard(true)
    setAccounts([])
    setAlerts([])
    getAndPopulateTables()
  }
  const handleOpenDialogAlerts = async (): Promise<void> => {
    setLoadingNewAlert(true)
    const token = await getAccessTokenSilently()
    const crUser: IUser | undefined = await fetchUser(
      controlRiskServerUrl,
      token,
      userId
    )
    if (crUser &&
        crUser.telegram &&
        crUser.telegram.chatId) {
      setOpenDialogNewAlert(true)
      setLoadingNewAlert(false)
    } else {
      setOpenDialogConnectTelegram(true)
      setLoadingNewAlert(false)
    }
  }
  const handleOpenSnackbar = (type: AlertColor, message: string) => {
    setTypeSnackbar(type)
    setMessageSnackbar(message)
    setOpenSnackbar(true)
  }
  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar(false)
  }

  return (
    <>
      {
        (!loadingDashboard && (!accounts || !accounts.length)) &&
        <CrAlertNewAccount
          handleOpenSnackbar = { handleOpenSnackbar }
        />
      }
      <CrTableDashboard
        accounts = { accounts }
        loadingDashboard = { loadingDashboard }
      />
      <CrTableHistories
        accounts = { accounts }
        loadingDashboard = { loadingDashboard }
      />
      <CrTableDrawdown
        accounts = { accounts }
        loadingDashboard = { loadingDashboard }
      />
      <CrTableAlerts
        accounts = { accounts }
        alerts = { alerts }
        setAlerts = { setAlerts }
        loadingDashboard = { loadingDashboard }
        handleOpenDialogAlerts = { handleOpenDialogAlerts }
        loadingNewAlert = { loadingNewAlert }
        setLoadingNewAlert = { setLoadingNewAlert }
      />
      <CrButtonRefresh
        handlerRefresh = { handlerRefresh }
        loadingDashboard = { loadingDashboard }
      />

      <CrDialogConnectTelegram
        openDialogConnectTelegram = { openDialogConnectTelegram }
        setOpenDialogConnectTelegram = { setOpenDialogConnectTelegram }
      />
      <CrDialogNewAlert
        accounts = { accounts }
        alerts = { alerts }
        setAlerts = { setAlerts }
        openDialogNewAlert = { openDialogNewAlert }
        setOpenDialogNewAlert = { setOpenDialogNewAlert }
        handleOpenSnackbar = { handleOpenSnackbar }
      />

      <CrSnackbar
        openSnackbar = { openSnackbar }
        handleCloseSnackbar = { handleCloseSnackbar }
        typeSnackbar= { typeSnackbar }
        messageSnackbar= { messageSnackbar }
      />
    </>
  );
}
