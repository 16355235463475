import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

 // EN
import enAppBar from './en/app-bar.json';
import enCommon from './en/common.json';
import enDashboard from './en/dashboard.json';

// ES
import esAppBar from './es/app-bar.json';
import esCommon from './es/common.json';
import esDashboard from './es/dashboard.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      appBar: enAppBar,
      common: enCommon,
      dashboard: enDashboard
    },
    es: {
      appBar: esAppBar,
      common: esCommon,
      dashboard: esDashboard
    }
  },
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false
  },
  ns: [
    'appBar',
    'common',
    'dashboard'
  ],
  defaultNS: 'common',
  debug: true,
});

export default i18n;
