import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { datetimeFormat, numberFormat } from '../../utils';
import { IAccount } from '../../interfaces';

export function CrTableDrawdown ({
  accounts,
  loadingDashboard,
}: {
  accounts: IAccount[] | undefined
  loadingDashboard: boolean
}) {
  return (
    <>
      <Typography variant="h5" sx={{ mt: 3 }} gutterBottom>
        Drawdown
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: 'grey.800' }}>
            <TableRow>
              <TableCell>Cuenta</TableCell>
              <TableCell align="right">Actual</TableCell>
              <TableCell align="right">Maximo</TableCell>
              <TableCell align="right">Registrado el</TableCell>
              <TableCell align="right">Desde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingDashboard ? (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            ) : (
              !accounts ? (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={5} align="center" sx={{ color: 'error.main' }}>
                    Lo sentimos, parece que hay un error
                  </TableCell>
                </TableRow>
              ) : !accounts.length ? (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={5} align="center">
                    Parece que aun no tienes ninguna cuenta activa
                  </TableCell>
                </TableRow>
              ) : (
                accounts.map((account: IAccount) => (
                  <TableRow key={account.number} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      {account.name}&nbsp;[&nbsp;{account.number}&nbsp;]
                    </TableCell>
                    <TableCell align="right" sx={(account.ddActual < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                      {numberFormat(account.ddActual * 100)}&nbsp;%
                    </TableCell>
                    <TableCell align="right" sx={(account.ddMax < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                      {numberFormat(account.ddMax * 100)}&nbsp;%
                    </TableCell>
                    <TableCell align="right">
                      {datetimeFormat(account.ddMaxDate)}
                    </TableCell>
                    <TableCell align="right">
                      {datetimeFormat(account.createdAt, false)}
                    </TableCell>
                  </TableRow>
                ))
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
