import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { capitalizeFirstLetter, numberFormat } from '../../utils'
import { IAccount } from '../../interfaces';

export function CrTableDashboardRow (props: any) {
  const account: IAccount = props.account
  const [open, setOpen] = useState(false);

  const getTotalLeverage = (
    openedOrderLeverage: number | undefined,
    pendingOrderLeverage: number | undefined
  ): number | undefined => {
    let totalLeverage;
    if (openedOrderLeverage && pendingOrderLeverage) {
      totalLeverage = openedOrderLeverage + pendingOrderLeverage
    } else if (pendingOrderLeverage) {
      totalLeverage = openedOrderLeverage
    } else if (pendingOrderLeverage) {
      totalLeverage = pendingOrderLeverage
    }
    return totalLeverage
  }
  const totalLeverage = getTotalLeverage(account.openedOrderLeverage, account.pendingOrderLeverage)

  function getFormattedElapsedTime(updatedAt: Date): string {
    const nowDate = new Date()
    const updatedAtDate = new Date(updatedAt)
    let diffInMilliseconds = nowDate.getTime() - updatedAtDate.getTime()

    const s = Math.floor((diffInMilliseconds / 1000) % 60)
    const m = Math.floor((diffInMilliseconds / (1000 * 60)) % 60)
    const h = Math.floor((diffInMilliseconds / (1000 * 60 * 60)) % 24)
    const d = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24))

    let formattedElapsedTime = ''
    if (d) {
      formattedElapsedTime = `${d} dias ${h} horas ${m} minutos ${s} segundos`
    } else if (h) {
      formattedElapsedTime = `${h} horas ${m} minutos ${s} segundos`
    } else if (m) {
      formattedElapsedTime = `${m} minutos ${s} segundos`
    } else if (s) {
      formattedElapsedTime = `${s} segundos`
    }
    return formattedElapsedTime
  }

  return (
    <>
      <TableRow key={account.number} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ pr: '8px', borderBottom: 'unset' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ pl: '8px', borderBottom: 'unset' }}>
          {account.name}&nbsp;[&nbsp;{account.number}&nbsp;]
        </TableCell>
        <TableCell align="right" sx={{borderBottom: 'unset'}}>
          {account.openedOrderLeverage ? numberFormat(account.openedOrderLeverage) : '-'}
        </TableCell>
        <TableCell align="right" sx={{borderBottom: 'unset'}}>
          {totalLeverage ? numberFormat(totalLeverage) : '-'}
        </TableCell>
        <TableCell align="right" sx={{borderBottom: 'unset'}}>
          {numberFormat(account.initialPositionsValue)}&nbsp;€
        </TableCell>
        <TableCell align="right" sx={{borderBottom: 'unset'}}>
          {numberFormat(account.actualPositionsValue)}&nbsp;€
        </TableCell>
        <TableCell align="right" sx={{borderBottom: 'unset'}}>
          {numberFormat(account.equity)}&nbsp;€
        </TableCell>
        <TableCell align="right" sx={(account.profit < 0) ? { color: 'error.main', borderBottom: 'unset' } : { color: 'success.main', borderBottom: 'unset' }}>
          {numberFormat(account.profit)}&nbsp;€
        </TableCell>
        <TableCell align="right" sx={(account.floating < 0) ? { color: 'error.main', borderBottom: 'unset' } : { color: 'success.main', borderBottom: 'unset' }}>
          {numberFormat(account.floating)}&nbsp;%
        </TableCell>
        <TableCell align="right" sx={{borderBottom: 'unset'}}>
          {numberFormat(account.balance)}&nbsp;€
        </TableCell>
        <TableCell align="center" sx={{borderBottom: 'unset'}}>
          {
            account.isConnected ? (
              <Tooltip title="La cuenta está conectada correctamente">
                <CloudIcon color="success"/>
              </Tooltip>
            ) : (
              <Tooltip title={`Parece que la cuenta no se está actualizando correctamente. Ultima actualización hace ${getFormattedElapsedTime(account.updatedAt)}`}>
                <CloudOffIcon color="error"/>
              </Tooltip>
            )
          }
        </TableCell>
      </TableRow>
      <TableRow key={`${account.number}-orders-details`}>
        <TableCell colSpan={11} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Ordenes abiertas
              </Typography>
              <Table size="small" aria-label="orders">
                <TableHead sx={{ bgcolor: 'grey.800' }}>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Symbol</TableCell>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="right">Lotaje</TableCell>
                    <TableCell align="right">Ap.&nbsp;Actual</TableCell>
                    <TableCell align="right">Pos.&nbsp;Inicial</TableCell>
                    <TableCell align="right">Pos.&nbsp;Actual</TableCell>
                    <TableCell align="right">PyG</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {account?.orders?.opened && account?.orders?.opened.length ?
                    account.orders.opened.map((order) => (
                      <TableRow key={order.ticket} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{order.ticket}</TableCell>
                        <TableCell>{order.symbol}</TableCell>
                        <TableCell align="center">
                          <Chip
                            label={capitalizeFirstLetter(order.type)}
                            sx={{ bgcolor: order.type === 'buy' ? 'error.light' : 'success.light' }}
                          />
                        </TableCell>
                        <TableCell align="right">{numberFormat(order.amount)}</TableCell>
                        <TableCell align="right">{numberFormat(order.initialPositionsValue / account.equity)}</TableCell>
                        <TableCell align="right">{numberFormat(order.initialPositionsValue)}&nbsp;€</TableCell>
                        <TableCell align="right">{numberFormat(order.actualPositionsValue)}&nbsp;€</TableCell>
                        <TableCell align="right" sx={(order.profit < 0) ? { color: 'error.main' } : { color: 'success.main' }}>{numberFormat(order.profit)}&nbsp;€</TableCell>
                      </TableRow>
                    )) : (
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell colSpan={9} align="center">
                          No hay Ordenes disponible
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>

              <Typography variant="h6" gutterBottom component="div" marginTop={2}>
                Ordenes pendientes
              </Typography>
              <Table size="small" aria-label="orders" sx={{marginBottom: 3}}>
                <TableHead sx={{ bgcolor: 'grey.800' }}>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Symbol</TableCell>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="right">Lotaje</TableCell>
                    <TableCell align="right">PyG</TableCell>
                    <TableCell align="right">Ap.&nbsp;Futuro</TableCell>
                    <TableCell align="right">Pos.&nbsp;Inicial</TableCell>
                    <TableCell align="right">Pos.&nbsp;Actual</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {account?.orders?.pending && account?.orders?.pending.length ?
                    account.orders.pending.map((order) => (
                      <TableRow key={order.ticket} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{order.ticket}</TableCell>
                        <TableCell>{order.symbol}</TableCell>
                        <TableCell align="center">
                          <Chip
                            label={capitalizeFirstLetter(order.type)}
                            sx={{ bgcolor: order.type === 'buy' ? 'error.light' : 'success.light' }}
                          />
                        </TableCell>
                        <TableCell align="right">{numberFormat(order.amount)}</TableCell>
                        <TableCell align="right">-</TableCell>
                        <TableCell align="right">{numberFormat(order.initialPositionsValue / account.equity)}</TableCell>
                        <TableCell align="right">{numberFormat(order.initialPositionsValue)}&nbsp;€</TableCell>
                        <TableCell align="right">{numberFormat(order.actualPositionsValue)}&nbsp;€</TableCell>
                      </TableRow>
                    )) :
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell colSpan={9} align="center">
                        No hay Ordenes disponible
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
