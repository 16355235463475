import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

export function CrButtonRefresh ({
  handlerRefresh,
  loadingDashboard
}: {
  handlerRefresh: () => void
  loadingDashboard: boolean
}) {
  return (
    <Button variant="contained" onClick={ handlerRefresh } color="primary" sx={{ mb: 3 }}>
      {
        loadingDashboard
        ? <CircularProgress size={24} color="inherit" />
        : <RefreshIcon />
      }
    </Button>
  );
}